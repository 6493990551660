import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaHome, FaBox, FaList, FaArrowLeft, FaArrowRight, FaLock, FaPlus, FaEye, FaStore } from 'react-icons/fa'; // Importing icons

const AdminLayout = ({children}) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false); // Sidebar toggle state

  useEffect(() => {
    const tokens = localStorage.getItem('token');
    if (tokens !== null) {
      setToken(tokens);
    } else {
      setToken('');
    }

    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      const name = userData.name;
      setName(name);
    }
  }, []);

  
  const handleLogout = ()=>{
    localStorage.removeItem('token');
    window.location.reload();
}


  return (
    <>
      {/* Navbar */}
      {token ? (
        <div className="bg-[#1927a1] text-white py-4 flex items-center justify-between px-0">
          {/* <button onClick={() => setIsCollapsed(!isCollapsed)} className="text-2xl">
            <FaBars />
          </button>
          <span className="font-semibold">Your Logo Here</span> */}
        </div>
      ) : (
        <div className="bg-[#1927a1] text-white py-2 px-4">
          <span className="font-semibold">A.M ADAMU GEN. ENTERPRISES</span>
        </div>
      )}

      {/* Sidebar */}
      <div className={`fixed top-0 left-0 h-full bg-[#1927a1] text-white transition-all ${isCollapsed ? 'w-16' : 'w-64'}`}>
        <div className="flex items-center justify-between p-4">
          <span className={`font-bold text-xl ${isCollapsed ? 'hidden' : 'block'}`}>A.M ADAMU GEN. ENTERPRISES</span>
          <button className="text-xl" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <FaArrowRight /> : <FaArrowLeft />}
          </button>
        </div>

        <div className="mt-6 overflow-y-auto h-[calc(100vh-16rem)] scrollbar-thin scrollbar-track-rounded scrollbar-thumb-rounded scrollbar-thumb-gray-300">
          <p className={`text-center font-bold mb-3 ${isCollapsed ? 'hidden' : ''}`}>{name}</p>
          <p className={`text-center font-semibold mb-3 ${isCollapsed ? 'hidden' : ''}`}>SALE AGENT</p>
          <ul>
            <li>
              <Link to="/dashboard" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaHome className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/sales/form" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaBox className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>POS</span>
              </Link>
            </li>
            <li>
              <Link to="/sales/admin-view" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaList className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>View Sales</span>
              </Link>
            </li>
          <li>
            <Link to="/product/add" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
              <FaPlus className="text-xl" />
              <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Add Product</span>
            </Link>
          </li>
          <li>
            <Link to="/product/list" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
              <FaEye className="text-xl" />
              <span className={`${isCollapsed ? 'hidden' : 'block'}`}>View Products</span>
            </Link>
          </li>
          
            <li>
              <Link to="/stock/add" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaPlus className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Add Stock</span>
              </Link>
            </li>


            <li>
              <Link to="/stock/view" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaEye className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>View Stocks</span>
              </Link>
            </li>
            <li>
              <Link to="/stock/report" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaStore className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Stocks Report</span>
              </Link>
            </li>
            <li>
              <Link to="/sales/report" className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaStore className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Sales Report</span>
              </Link>
            </li>
            <li>
              <Link to="/sales/view"  onClick={handleLogout} className="flex items-center gap-4 p-3 hover:bg-[#1927a1]">
                <FaLock className="text-xl" />
                <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Logout</span>
              </Link>
            </li>
            <li>
        </li>
          </ul>
        </div>
      </div>

      {/* Main content */}
      <div className={`transition-all duration-300 ${isCollapsed ? 'ml-10' : 'ml-64'} p-4`}>
       
      </div>
    </>
  );
};

export default AdminLayout;
