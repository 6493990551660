import { useEffect,useRef, useState } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


import InputField from "../components/InputField";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

import $ from 'jquery';
import 'dropify/dist/js/dropify.min.js';
import 'dropify/dist/css/dropify.min.css';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { Dialog } from 'primereact/dialog';
import DateUtil from '../components/DateUtil';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import InventoryReportTable from '../components/InventoryReportTable';
import '../../src/components/report.css'
import './receipt.css'

export default function ProductReport({ auth }) {
    const [customers, setCustomersData] = useState([]);
    const [productData, setProductData] = useState([]);

    const [globalFilter, setGlobalFilter] = useState('');
    const [visible, setVisible] = useState(false);

    const [selectedSubCat, setSelectedSubCat] = useState([]);
    const [selectedCat, setSelectedCat] = useState('');
    const [catVal, setCatVal] = useState('');
    const [subCatVal, setSubCatVal] = useState('');
    const [subCatArray, setSubCatArray] = useState('');
    const [totals,setTotal] = useState([])
    const [report,setReport] = useState([])
    const [sales,setSales] = useState([])
    // const [productId, setProductId] = useState(null);


    const [catId, setCatId] = useState(null);
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        description: '',
        image: '',
        unit: '',
        qty:'',
        selling_price: '',
        discount: ''
    });

    const { productId }  = useParams();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    // const report = [
    //     { product_name: "Laptop", stock_quantity: 50, selling_price: 800, potential_revenue: 40000 },
    //     { product_name: "Smartphone", stock_quantity: 100, selling_price: 500, potential_revenue: 50000 },
    //   ];
      
    //   const totals = {
    //     total_items_in_stock: 150,
    //     total_potential_revenue: 90000,
    //   };

    const toast = useRef(null);

    const showSuccess = (severity,summary,message) => {
        toast.current.show({severity:severity, summary: summary, detail:message, life: 10000});
    }
        const accept = () => {
                if (toast.current) {
                    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 10000 });
                } else {
                    console.error('Toast reference is null');
                }
            };

            const reject = () => {
                if (toast.current) {
                    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 10000 });
                } else {
                    console.error('Toast reference is null');
                }
            };

            const confirm1 = () => {
                confirmDialog({
                    group: 'headless',
                    message: 'Are you sure you want to delete?',
                    header: 'Confirmation',
                    icon: 'pi pi-trash',
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            };


     const  fetchProduct  = async ()=>{
        try {
            const response = await axios.get('https://test.verifyagents.com.ng/api/products', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Product added:', response.data);


                setProductData(response.data)
                console.log("product data",productData)

        } catch (error) {
            console.error('Error adding product:', error);
        }
     }

      useEffect(() => {
        fetchProduct();
      }, []);

   


    const dropifyRef = useRef(null);

    useEffect(() => {
        // Initialize Dropify
        $(dropifyRef.current).dropify();

        // Clean up Dropify instance on unmount
        return () => {
            if (dropifyRef.current) {
                $(dropifyRef.current).dropify('destroy');
            }
        };
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevState) => ({
            ...prevState,
            image: file
        }));

    };


     const  fetch  = async ()=>{
        try {
            const response = await axios.get('https://test.verifyagents.com.ng/api/stocks', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Product added:', response.data);

                setCustomersData(response.data)
                console.log(customers)

        } catch (error) {
            alert("failed to load purchases")
            console.error('Error adding product:', error);
        }
     }

     const  fetchSales  = async ()=>{
        try {
            const response = await axios.get('https://test.verifyagents.com.ng/api/sales/report-product/'+productId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Product added:', response.data);

                setTotal(response?.data?.totals)
                setReport(response?.data?.report)

        } catch (error) {
            alert("failed to load purchases")
            console.error('Error adding product:', error);
        }
     }
    useEffect(() => {
        fetchSales();
        fetch();
 
        // setCustomersData([

    }, []);

    const ImageBody = (rowData) => {
        return (

            <Image src={rowData?.product?.image} zoomSrc={rowData?.product?.image}  alt="Image" width="40" height="30" preview />

        );
    };


    const dt = useRef(null);

    const navigate =  useNavigate();
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        const doc = new jsPDF();

        // Define columns for the PDF
        const columns = [
            { header: 'Product Name', field: 'name' },
            { header: 'Price', field: 'selling_price' },
            { header: 'Category', field: 'sub_category' },
        ];

        // Extract headers
        const headers = columns.map(col => col.header);

        // Extract data
        const data = productData.map(item => [
            item.name,
            item.selling_price,
            item.sub_category
        ]);
        
        // doc.addImage(logo, 'PNG', 80, 10, 50, 20); // Adjust the x, y, width, and height as needed

        // Add shop header, make it bold and center it
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.text("Jigawa Palliative Shops", doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

        // Add subtitle, make it bold and center it
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Product Pricing', doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

        // Add current date to the header
        const currentDate = new Date().toLocaleDateString();
        doc.setFontSize(10);
        doc.text(`Date: ${currentDate}`, doc.internal.pageSize.getWidth() / 2, 60, { align: 'center' });

        // Add a margin before the table starts
        const margin = 70; // Adjust the margin value as needed

        // Reset text color to black for table content
        doc.setTextColor(0, 0, 0);


        // Generate PDF table
        autoTable(doc, {
            head: [headers],
            body: data,
            startY: margin,
            styles: {
                fillColor: [240, 240, 240] // Light grey background color for all rows
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255] // White background color for alternate rows (optional)
            },
            headStyles: {
                fillColor: [255, 1, 1], // Blue background color for header row
                textColor: [255, 255, 255] // White text color for header row
            },
        });

        doc.save('pricing.pdf');
    };

    const actionButtonTemplate = (rowData) => {
        return (
          <div className='grid grid-cols-2 justify-center'>
            <button
           tooltip="Enter your username"
              className="p-button-rounded text-blue-800 w-12 h-12 p-button-warning mr-2"
            onClick={()=>{ setFormData(rowData); !visible && setVisible(true); }}
            >
              <i className="pi pi-pencil w-12 text-danger"></i>
            </button>
            <button
            tooltip="Enter your username"
              className="p-button-rounded p-button-danger"
                onClick={confirm1}
            >
              <i className="pi pi-trash text-red-500"></i>
            </button>
          </div>
        );
      };

    const priceTemplate = (rowData)=>{
            return(
                <>
                <span>₦‎ {new Intl.NumberFormat().format(rowData?.selling_price)}</span>
                </>
            )
    }


   


    const catTemplate = (rowData)=>{
        return(
            <>
            <span>{rowData.category}</span>
            </>
        )
}

const stockTemplate = (rowData)=>{
    return(
        <>
        <span>{rowData.qty_in - rowData.qty_out}</span>
        </>
    )
}



    const header = (
        <div className="flex justify-between pl-4">
          <h2 className="text-2xl text-dark font-bold mb-4"></h2>

          <span className="p-input-icon-left">
            <InputText
              type="search"
              className='w-50  h-5 m-3 border border-blue-500 py-3 px-2'
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
             placeholder='Search by any filter'
            />
          </span>
        </div>
      );

    return (       
        <div className="container w-full my-5 mb-5 bg-blue-400 rounded-2xl mx-auto px-3 sm:px-4 lg:px-2 py-8 print:bg-white print:shadow-none" id='report'>
        <h2 className="text-white text-2xl font-bold mb-5 text-center print:text-black">
          Product Sale Report
        </h2>
  
        {/* Totals Section */}
        <div className="bg-blue-600 p-4 rounded-lg mb-5 text-white print:bg-white print:text-black">
          <p><strong>Total Sales:</strong> {totals.total_sales}</p>
          <p><strong>Total Revenue:</strong> ₦{Intl.NumberFormat().format(totals.total_revenue)}</p>
        </div>
  
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse bg-white rounded-lg shadow-lg print:shadow-none print:border-black">
            <thead>
              <tr className="bg-blue-600 text-white print:bg-gray-200 print:text-black">
                <th className="py-3 px-4 text-left">Invoice No</th>
                <th className="py-3 px-4 text-left">Product Name</th>
                <th className="py-3 px-4 text-left">Quantity Sold</th>
                <th className="py-3 px-4 text-left">Sold By</th>
                <th className="py-3 px-4 text-left">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {report.map((sale, index) => (
                <tr
                  key={index}
                  className={`border-b ${index % 2 === 0 ? "bg-gray-100" : "bg-white"} print:bg-white`}
                >
                  <td className="py-3 px-4">{sale.invoice_no}</td>
                  <td className="py-3 px-4">{sale.product_name}</td>
                  <td className="py-3 px-4">{sale.quantity_sold}</td>
                  <td className="py-3 px-4">{sale.sold_by}</td>
                  <td className="py-3 px-4">₦{Intl.NumberFormat().format(sale.total_price)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {/* Print Button */}
        <div className="text-center mt-5">
          <button
            className="bg-blue-700 text-white py-2 px-4 rounded-lg hover:bg-blue-800 print:hidden"
            onClick={() => window.print()}
          >
            Print Report
          </button>
        </div>
      </div>
    );
}
