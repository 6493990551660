import { useEffect,useRef, useState } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


import InputField from "../components/InputField";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

import $ from 'jquery';
import 'dropify/dist/js/dropify.min.js';
import 'dropify/dist/css/dropify.min.css';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';
import { Dialog } from 'primereact/dialog';
import DateUtil from '../components/DateUtil';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { Navigate, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';


export default function ViewStocks({ auth }) {
    const [customers, setCustomersData] = useState([]);

    const [globalFilter, setGlobalFilter] = useState('');
    const [visible, setVisible] = useState(false);

    const [selectedSubCat, setSelectedSubCat] = useState([]);
    const [selectedCat, setSelectedCat] = useState('');
    const [catVal, setCatVal] = useState('');
    const [subCatVal, setSubCatVal] = useState('');
    const [subCatArray, setSubCatArray] = useState('');

    const [catId, setCatId] = useState(null);
    const [productData, setProductData] = useState([]);
    const [products, setProducts] = useState([]);
    const [FormArray, setFormArray] = useState([]);
    const [copyProduct, setCopyProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [clickedProduct, setClickedProduct] = useState([]);
    const [processing, setProcessing] = useState(false);

    const [formData, setFormData] = useState({
        qty: '',
        selling_price: '',
        product_id: '',
        updatedPrice:false,
        updatedQty:false,
    });


    



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const toast = useRef(null);

    const showSuccess = (severity,summary,message) => {
        toast.current.show({severity:severity, summary: summary, detail:message, life: 10000});
    }
        const accept = () => {
                if (toast.current) {
                    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 10000 });
                } else {
                    console.error('Toast reference is null');
                }
            };

            const reject = () => {
                if (toast.current) {
                    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 10000 });
                } else {
                    console.error('Toast reference is null');
                }
            };

            const confirm1 = () => {
                confirmDialog({
                    group: 'headless',
                    message: 'Are you sure you want to delete?',
                    header: 'Confirmation',
                    icon: 'pi pi-trash',
                    defaultFocus: 'accept',
                    accept,
                    reject
                });
            };


     const  fetchProduct  = async ()=>{
        try {
            const response = await axios.get('https://test.verifyagents.com.ng/api/products', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
                console.log('Product added:', response.data);

                setProductData(response.data)
                console.log("product data",productData)

        } catch (error) {
            console.error('Error adding product:', error);
        }
     }


     
    const handleProductChange = (product) => {

        setFormData((prevState) => ({
            ...prevState,
            product_id: product,
        }));



        // setSelectedProduct((prevState) => {
            // const selectedProductExists = prevState.some((product) => product.id === product.id);

            // if (!selectedProductExists) {
            //     setCopyProduct({ id: value, name: name });
            //     console.log("copy1", copyProduct)

            //     console.log("form1", selectedProduct);
            //     return [{ id: value, name: name }];
            // } else {

            //     setCopyProduct(prevState.id);
            //     console.log("copy2", copyProduct)
            //     return prevState;
            // }

        // });



        console.log('selectedProduct:', selectedProduct)
    };



      useEffect(() => {
        fetchProduct();
      }, []);

      const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);

        const form = new FormData();
        form.append('price', formData.price !== undefined ? formData.price : formData.product_id?.selling_price);
        form.append('qty', formData.qty);
        form.append('product_id', formData.product_id?.id);
        // form.append('updateFormData', JSON.stringify(FormArray));


        console.log("Copy prod", copyProduct)

        try {
            const response = await axios.post('https://test.verifyagents.com.ng/api/stocks', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });


            fetch();
            setVisible(false)
            
            console.log('Stock added:', response.data);
            showSuccess('success', 'Stock', 'Purchase successfully placed');
            setTimeout(()=>{
                setFormData([])
            },2000)
        } catch (error) {
            console.error('Error adding stock:', error);
            showSuccess('error', 'Stock', 'Failed to Purchase');
        } finally {
            setProcessing(false);
        }
    };


    const dropifyRef = useRef(null);

    useEffect(() => {
        // Initialize Dropify
        $(dropifyRef.current).dropify();

        // Clean up Dropify instance on unmount
        return () => {
            if (dropifyRef.current) {
                $(dropifyRef.current).dropify('destroy');
            }
        };
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevState) => ({
            ...prevState,
            image: file
        }));

    };


     const  fetch  = async ()=>{
        try {
            const response = await axios.get('https://test.verifyagents.com.ng/api/stocks', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Product added:', response.data);

                setCustomersData(response.data)
                console.log(customers)

        } catch (error) {
            alert("failed to load purchases")
            console.error('Error adding product:', error);
        }
     }
    useEffect(() => {
        fetch();
        // setCustomersData([
        //     {id:1,name:'Rice',unit:'34kh' },
        //     {id:2,name:'Rice',unit:'34kh' },
        //     {id:3,name:'Rice',unit:'34kh' },
        //     {id:4,name:'Rice',unit:'34kh' },

        // ])

    }, []);

    const ImageBody = (rowData) => {
        return (

            <Image src={rowData?.product?.image} zoomSrc={rowData?.product?.image}  alt="Image" width="40" height="30" preview />

        );
    };


    const dt = useRef(null);

    const navigate =  useNavigate();
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        const doc = new jsPDF();

        // Define columns for the PDF
        const columns = [
            { header: 'Product Name', field: 'name' },
            { header: 'Price', field: 'selling_price' },
            { header: 'Category', field: 'sub_category' },
        ];

        // Extract headers
        const headers = columns.map(col => col.header);

        // Extract data
        const data = productData.map(item => [
            item.name,
            item.selling_price,
            item.sub_category
        ]);
        
        // doc.addImage(logo, 'PNG', 80, 10, 50, 20); // Adjust the x, y, width, and height as needed

        // Add shop header, make it bold and center it
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.text("Jigawa Palliative Shops", doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

        // Add subtitle, make it bold and center it
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Product Pricing', doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });

        // Add current date to the header
        const currentDate = new Date().toLocaleDateString();
        doc.setFontSize(10);
        doc.text(`Date: ${currentDate}`, doc.internal.pageSize.getWidth() / 2, 60, { align: 'center' });

        // Add a margin before the table starts
        const margin = 70; // Adjust the margin value as needed

        // Reset text color to black for table content
        doc.setTextColor(0, 0, 0);


        // Generate PDF table
        autoTable(doc, {
            head: [headers],
            body: data,
            startY: margin,
            styles: {
                fillColor: [240, 240, 240] // Light grey background color for all rows
            },
            alternateRowStyles: {
                fillColor: [255, 255, 255] // White background color for alternate rows (optional)
            },
            headStyles: {
                fillColor: [255, 1, 1], // Blue background color for header row
                textColor: [255, 255, 255] // White text color for header row
            },
        });

        doc.save('pricing.pdf');
    };

 
    const handleViewStockHistory = (stock) => {
        navigate('/stock-history/'+stock)
        console.log("Viewing stock history for product:", stock);
        // Add logic to fetch and display stock history
      };
      
      const handleViewSalesReport = (product) => {
        navigate('/sales/product-report/'+product.id)
        console.log("Viewing sales report for product:", product)
        // Add logic to fetch and display sales report
      };
      

    const actionButtonTemplate = (rowData) => {
        return (
          <div className="grid grid-cols-3 gap-2 justify-center">
            {/* Edit Button */}
            <Button
              tooltip="Edit Stock"
              icon="pi pi-pencil"
              className="p-button-rounded p-button-warning"
              onClick={() => {
                handleProductChange(rowData.product);
                !visible && setVisible(true);
              }}
            />
      
            {/* View Stock History Button */}
            <Button
              tooltip="View Stock History"
              icon="pi pi-clock"
              className="p-button-rounded p-button-success"
              onClick={() => handleViewStockHistory(rowData.id)}
            />
      
            {/* View Sales Report Button */}
            <Button
              tooltip="View Sales Report"
              icon="pi pi-chart-bar"
              className="p-button-rounded p-button-info"
              onClick={() => handleViewSalesReport(rowData.product)}
            />
          </div>
        );
      };
      

    const priceTemplate = (rowData)=>{
            return(
                <>
                <span>₦‎ {new Intl.NumberFormat().format(rowData?.selling_price)}</span>
                </>
            )
    }


   


    const catTemplate = (rowData)=>{
        return(
            <>
            <span>{rowData.category}</span>
            </>
        )
}

const stockTemplate = (rowData)=>{
    return(
        <>
        <span>{rowData.qty_in - rowData.qty_out}</span>
        </>
    )
}



    const header = (
        <div className="flex justify-between pl-4">
          <h2 className="text-2xl text-dark font-bold mb-4"></h2>

          <span className="p-input-icon-left">
            <InputText
              type="search"
              className='w-50  h-5 m-3 border border-blue-500 py-3 px-2'
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
             placeholder='Search by any filter'
            />
          </span>
        </div>
      );

    return (






<div className="container w-full my-5 mb-5 bg-blue-400 rounded-2xl mx-auto px-3 sm:px-4 lg:px-2 py-8">
<Toast ref={toast} />
            <ConfirmDialog
                group="headless"
                content={({ headerRef, contentRef, footerRef, hide, message }) => (
                    <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
                        <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                            <i className="pi pi-question text-5xl"></i>
                        </div>
                        <span className="font-bold text-2xl block mb-2 mt-4" ref={headerRef}>
                            {message.header}
                        </span>
                        <p className="mb-0" ref={contentRef}>
                            {message.message}
                        </p>
                        <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>
                            <Button
                                label="Save"
                                onClick={(event) => {
                                    hide(event);
                                    accept();
                                }}
                                className="w-8rem"
                            ></Button>
                            <Button
                                label="Cancel"
                                outlined
                                onClick={(event) => {
                                    hide(event);
                                    reject();
                                }}
                                className="w-8rem"
                            ></Button>
                        </div>
                    </div>
                )}
            />

<div className="card flex justify-content-center">

            <Dialog header="Stock Adjustments" visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }}>
            <div className="mx-auto w-full bg-white p-6 rounded-md justify-between mb-6">
                <h1 className="font-bold mx-auto text-4xl place-self-start text-blue-500 justify-center text-left">Stock Purchase</h1>
                <Toast ref={toast} />
                <form onSubmit={handleSubmit}>

                    {/* <label htmlFor="supplier_id" className="my-3 block font-bold text-base font-medium text-[#000000]">
                        Select Product
                    </label>
                    <div className="card flex justify-content-center w-full my-3">
                        <Dropdown
                            inputId="product_id"
                            name='product_id'
                            value={formData.product_id}
                            onChange={handleProductChange}
                            options={productData}
                            panelClassName='text-blue-500 border border-blue-500'
                            optionLabel="name"
                            className="w-full text-blue-500 border border-blue-500"
                        />
                    </div> */}
                         


                   
                    <label htmlFor="supplier_id" className="my-3 block font-bold text-base font-medium text-[#000000]">
                        Product
                    </label>
                    <div className='grid grid-cols-2 mt-3'>
                    <input
                            id="qty"
                            label="Product"
                            type="text"
                            name="product_id"    
                            placeholder="Quantity"
                            value={formData?.product_id?.name}
                           
                            className="rounded-md w-full mb-5 py-3 px-5 border w-20  border-blue-500 bg-white px-1  text-base font-medium text-[#21318b] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                    <label htmlFor="supplier_id" className="my-3 block font-bold text-base font-medium text-[#000000]">
                            Quantity
                    </label>
                    <div className='grid grid-cols-2 mt-3'>
                    <input
                            id="qty"
                            label="Purchase Quantity"
                            type="number"
                            name="qty"
                            placeholder="Quantity"
                            value={formData.qty}
                            required
                            onChange={handleInputChange}
                            className="rounded-md w-full mb-5 py-3 px-5 border w-20  border-blue-500 bg-white px-1  text-base font-medium text-[#21318b] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />



                    <label htmlFor="supplier_id" className="my-2 block font-bold text-base font-medium text-[#000000]">
                        Selling Price
                    </label>
                    <input
                        id="price"
                            type="number"
                            name="Selling price"
                            placeholder="Price"
                            value={formData?.product_id?.selling_price}
                            onChange={handleInputChange}

                            className="rounded-md w-full mb-3 py-3 px-5 border w-20  border-blue-500 bg-white px-1  text-base font-medium text-[#201e83] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />




                    </div>


                        <button
                            type="submit"
                            disabled={processing}
                            className={`hover:shadow-form grid grid-cols-2 w-full items-center justify-content-center py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-blue-300' : 'bg-blue-500'}`}
                        >
                            {processing && (
                                <div className="items-center">
                                    <ProgressSpinner style={{ width: '30px', height: '30px', color: 'red' }} strokeWidth="6" fill="none" animationDuration=".2s" />
                                </div>
                            )}
                            <div className="items-center justify-center text-white">
                                Add Stock
                            </div>
                        </button>
                    </div>
                </form>
            </div>
            
            </Dialog>
        </div>


<h1 className='text-gray-700 font-bold text-2xl'>Stock List</h1>

<DataTable
  value={customers}
  scrollable
  rowHover
  className=' my-5  '
  paginator rows={10}
  rowsPerPageOptions={[20,40,80,120,160,220]}
   scrollHeight="300px"
  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
  currentPageReportTemplate="{first} to {last} of {totalRecords}"
  tableStyle={{ maxWidth: '100%', height:"50%" }}
  globalFilter={globalFilter}
  header={header}
>
  <Column
    field="id"
    header="ID"
    className='p-4'
    sortable
    style={{ minHeight: '12rem' }}
    filter
    filterPlaceholder="Search by ID"
  />


  <Column
    field="product.name"
    header="Product"
    sortable
    filter
    filterPlaceholder="Search by Product Name"
  />



<Column
    field="qty"
    header="Quantity"
    style={{ minHeight: '20rem' }}
    sortable
    filter
    filterPlaceholder="Search by Product Name"
  />


<Column
    body={priceTemplate}
    header="Price"
    sortable
    filter
    filterPlaceholder="Search by Product Price"
  />





  <Column header="Actions" body={actionButtonTemplate} />

</DataTable>
</div>
    );
}
