import React, { useState, useRef, useEffect } from 'react';
import InputField from "../components/InputField";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import $ from 'jquery';
import 'dropify/dist/js/dropify.min.js';
import 'dropify/dist/css/dropify.min.css';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { useLocation } from 'react-router-dom';
import Preloader from '../components/Preloader';

export default function AddProduct() {

    const [visible, setVisible] = useState(false);
    const [subCatArray, setSubCatArray] = useState('');
    const [processing, setProcessing] = useState(false);
    const [catId, setCatId] = useState(null);
    const [itemType, setItemType]=useState(null);
    const [type, setType]=useState(false);
    const [subCatData, setSubCatData] = useState([]);
    const [catData, setCatData] = useState([]);

    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
      // Simulate loading
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000); // Adjust the timeout as needed

      return () => clearTimeout(timer);
    }, [location]);

    const [formData, setFormData] = useState({
        name: '',
        selling_price: '',
        qty: '',
        size: '',
        type: '',
    });

    

    const [itemFormData, setItemFormData] = useState({
        item: '',
        type: '',
    });

   

    const productType = [
        {'id': 'item1', 'name': 'Item 1'},
        {'id': 'item2', 'name': 'Item 2'},
    ]
  

 

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };



    const handleTypeChange = (value) => {

        setItemFormData((prevState) => ({
            ...prevState,
            type: value
        }));

        if (value == "Cat") {
            setType(true);

    }else{
        setType(false);

    }

    };


   
  

    const toast = useRef(null);
    const showSuccess = (severity, summary, message) => {
        toast.current.show({ severity: severity, summary: summary, detail: message, life: 10000 });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);

        const form = new FormData();
        form.append('name', formData.name);
        form.append('selling_price', formData.selling_price);
        form.append('qty', formData.qty);
        form.append('size', formData.size);
        form.append('type', formData.type);

        try {
            const response = await axios.post('https://test.verifyagents.com.ng/api/products', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Product added:', response.data);
            setProcessing(false);
            if (response.data.success) {
                showSuccess('success', "Product", "Product Successfully Added");
            } else {
                showSuccess('error', "Product", "Product failed to add");
            }
        } catch (error) {
            setProcessing(false);
            console.error('Error adding product:', error);
        }
    };




     const  fetchDataSub  = async ()=>{
        try {
            const response = await axios.get('https://test.verifyagents.com.ng/api/product/item?type=2', {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log('Product items fetched:', response.data);

            const data = response.data; // Destructure cat and subCat from response.data

            setSubCatData(data);

        } catch (error) {
            console.error('Error adding product:', error);
        }
     }


    // useEffect(() => {
    //     fetchDataCat() ;
    //     fetchDataSub() ;
    // }, []);


    const handleUpdate = async (e) => {
        e.preventDefault();
        setProcessing(true);

        const form = new FormData();
        form.append('items', itemFormData.item);
        form.append('type', itemFormData.type);

        try {
            const response = await axios.post('https://test.verifyagents.com.ng/api/product/create', form, {
                headers: {
                     'Content-Type': 'application/json'
                }
            });

            console.log('Product Item added:', response.data);
            setProcessing(false);

            if (response.data.success) {
                setItemFormData([])
                fetchDataSub();
                // fetchDataCat();
                showSuccess('success', "Product", "Product Category/Sub Category  Successfully Added");
            } else {
                showSuccess('error', "Product", "Product failed to add");
            }
        } catch (error) {
            setProcessing(false);
            console.error('Error adding product:', error);
        }
    };






    const dropifyRef = useRef(null);

    useEffect(() => {
        // Initialize Dropify
        $(dropifyRef.current).dropify();

        // Clean up Dropify instance on unmount
        return () => {
            if (dropifyRef.current) {
                $(dropifyRef.current).dropify('destroy');
            }
        };
    }, []);



    return (



        <div className="flex items-center justify-center p-12 w-full mx-auto">
            <div className="mx-auto w-full bg-white p-6 rounded-md justify-between ">
            <h1 className=" font-bold mx-auto text-4xl place-self-start text-[#1927a1] justify-center text-left">Add Product</h1>
            <Toast ref={toast} />
                <form onSubmit={handleSubmit}>
                <h1 className=" font-bold text-2xl place-self-start mb-4">Product Information</h1>
                
                        <InputField
                            id="name"
                            label="Product Name"
                            type="text"
                            name="name"
                            placeholder="Product Name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                      


                        <InputField
                            id="selling_price"
                            label="Selling Price"
                            type="number"
                            name="selling_price"
                            placeholder="Selling Price"
                            value={formData.selling_price}
                            onChange={handleInputChange}
                        />
                        {/* <InputField 
                        id={"qty"}
                        label="Quantity"
                        type="number"
                        name="qty"
                        placeholder="Quantity"
                        value={formData.qty}
                        onChange={handleInputChange}
                        /> */}

                        {/* <InputField
                            id="size"
                            label="Size"
                            type="text"
                            name="size"
                            placeholder="Size"
                            value={formData.size}
                            onChange={handleInputChange}
                        /> */}

                        <div className='my-3'>
                            <label  className="mb-3 block text-base font-medium font-bold text-[#07074D]">
                            Product Type
                            </label>
                            <Dropdown
                                id="type"
                                label="Type"
                                name="type"
                                options={productType}
                                optionValue='id'
                                pt={true}
                                optionLabel='name'
                                style={{color: '#1927a1'}}
                                className='w-full ring-blue-700'
                                placeholder="Type"
                                value={formData.type}
                                onChange={handleInputChange}
                            />
                        </div>
           
                    <div>
                        <button
                        onClick={handleSubmit}
                            type="submit"
                            { ...processing && 'disabled'}

                            className={`hover:shadow-form grid grid-cols-2 w-full items-center justify-content-center file:rounded-md  py-3 px-4 text-center text-base font-semibold outline-none ${processing ? 'bg-[#4153f8]' : 'bg-[#1927a1]'}`}
                        >{processing && (
                             <div className="items-center">
                                <ProgressSpinner style={{width: '30px', height: '30px', color:'red'}} strokeWidth="6" fill="none" animationDuration=".2s" />
                        </div>
                        )

                        }
                        <div className="items-center justify-center text-white">
                                Add Product
                        </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
