import axios from 'axios';

const ProductService = {
    fetchProducts: async () => {
        try {
            const response = await axios.get('https://test.verifyagents.com.ng/api/products', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log('Products:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching products:', error);
            throw error; // Optionally rethrow to handle further up
        }
    }
};

export default ProductService;
