import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout from './SidebarLayout'; // Collapsible Sidebar Component
import Footer from './Footer';
import BiziAgentLayout from './BiziAgentLayout';
import StakeholderLayout from './StakeholderLayout';
import ShopAgentLayout from './ShopAgentLayout';
import StoreAgentLayout from './StoreAgent';
import FinanceLayout from './FinanceLayout';
import StoreAdminLayout from './StoreAdminLayout';
import BiziAdminLayout from './BiziAdminLayout';
import BiziCardAgentLayout from './BiziCardAgent';
import "primereact/resources/themes/lara-light-blue/theme.css";
import AdminLayout from './AdminLayout';

const Header = () => {
  return (
    <header className="w-full bg-[#1927a1] text-white py-4 px-6 shadow-md flex justify-between items-center">
      <h1 className="text-xl font-bold"></h1>
      {/* Add any additional header components like profile, notifications */}
      <div className="space-x-4">
        {/* Example icons or components */}
        {/* <button className="text-sm">Notifications</button>
        <button className="text-sm">Profile</button> */}
      </div>
    </header>
  );
};

const Layout = ({ children }) => {
  const [token, setToken] = useState(null);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem('token');
    const roleFromStorage = localStorage.getItem('role');
    setToken(tokenFromStorage);
    setRole(roleFromStorage);

    // If there's no token, redirect to the login page
    if (!tokenFromStorage) {
      navigate('/login');
    }
  }, [navigate]);

  const getLayoutComponent = () => {
    switch (role) {
      case 'admin':
        return <AdminLayout />;
      case 'bizi_admin':
        return <BiziAdminLayout />;
      case 'bizi_agent':
        return <BiziAgentLayout />;
      case 'bizi_card_agent':
        return <BiziCardAgentLayout />;
      case 'bizi_agent_staff':
        return <BiziAgentLayout />;
      case 'shop_agent':
        return <ShopAgentLayout />;
      case 'store_agent':
        return <StoreAgentLayout />;
      case 'stakeholder':
        return <StakeholderLayout />;
      case 'finance':
        return <FinanceLayout />;
      case 'shop_admin':
        return <StoreAdminLayout />;
      default:
        return <div className="text-center mt-4"></div>; // Fallback content
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Horizontal Header */}
      {token && <Header />}

      <main className="flex-grow flex">
        {/* Sidebar and Main Content */}
        {getLayoutComponent()} {/* Sidebar for role */}
        {children}
      </main>

      {/* Footer */}
      <Footer className="bg-gray-800 text-white py-4 text-center fixed bottom-0 left-0 w-full" />
    </div>
  );
};

export default Layout;
