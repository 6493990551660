import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "primereact/resources/themes/lara-light-blue/theme.css";
import { FilterMatchMode, PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { classNames } from 'primereact/utils';

// Create the root and render the application
const root = ReactDOM.createRoot(document.getElementById('root')); // Ensure this ID matches

const value = {
    appendTo: 'self',
  
    ripple: true,
    unstyled: false,
};

root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <App />
    </PrimeReactProvider>
  </React.StrictMode>
);

// Optional: Measure performance
reportWebVitals();
